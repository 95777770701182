<template>
<v-container fluid class="pa-0 ma-0 v-dialog--scrollable" style="max-width: 540px; max-height: 80%;">
<v-card light class="elevation-0">
<v-app-bar flat color="primary" dense dark>
    <v-toolbar-title><v-icon class="mr-3" color="success">mdi-cogs</v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="resetFilter"><v-icon :color="(filterCount) ? 'red' : 'grey lighten-5'" title="Effacer tous les filtres">mdi-broom</v-icon></v-btn>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey lighten-5'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey lighten-5'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>
<v-card-text class="pa-0" style="max-height:600px;">
<v-layout row wrap class="ma-0 pl-2 pr-2 pt-3 pb-4" >
    <!-- <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date" :objectForm="usrFilter" fieldName='min_tsk_date_plan'></DatePicker>
    </v-flex>
    <v-flex xs6 class="pl-2 pr-2">
        <DatePicker label="Date" :objectForm="usrFilter" fieldName='max_tsk_date_plan'></DatePicker>
    </v-flex> -->
    <v-flex xs6 class=""></v-flex>
    <v-flex xs6 class="text-right" >
        <v-btn small class="mt-0 mb-2" outlined color="indigo" @click="addDates">
            <v-icon>mdi-calendar-plus</v-icon>Ajouter des dates
        </v-btn>
    </v-flex>
    <template v-for="(item, index) in datesRange" >
        <v-flex xs5 class="pl-2 pr-2" :key="'min' + index" >
            <DatePicker label="Executé le" :objectForm="item" fieldName='min_tsk_date_plan' 
                :style="'border-left: 3px solid ' + colorPalette[index] + ';'">
            </DatePicker>
        </v-flex>
        <v-flex xs4 class="pl-2 pr-2" :key="'max' + index">
            <DatePicker label="Jusqu'au" :objectForm="item" fieldName='max_tsk_date_plan'></DatePicker>
        </v-flex>
        <v-flex xs3 class="pl-2 pr-2 text-right" :key="'del' + index">
            <v-btn x-small v-if="(!item['min_tsk_date_plan'] || !item['max_tsk_date_plan'])" 
                title="Un ou plusieurs champs non renseigné(s)"
                class="ma-2" outlined fab color="orange">
                <v-icon>mdi-alert-outline</v-icon>
            </v-btn>
            <v-btn x-small v-if="index" class="ma-2" outlined fab color="red" @click="removeDate(index)">
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </v-flex>
    </template>
    <v-flex xs12 class="pl-2 pr-2 pa-0">
        <AutocompleteMulti 
            :title="'Actions'" :ressource="'tasktypes'"
            :listObject.sync="usrFilter.tsk_tty_id" :listParent.sync="filterSource.tsk_tty_id" 
            :fields="{ 
                index: 'tty_id', text:'tty_name', autofocus: true, outlined: true,  dense: true,
                search:'tty_name', multiSelect:true, prefetch: true, params:'' 
        }"></AutocompleteMulti>
    </v-flex>
    <v-flex xs12 class="pl-2 pr-2 pa-0">
        <AutocompleteMulti 
            :title="'Type'"
            :listObject.sync="usrFilter.tsk_typ_id" :listParent.sync="filterSource.tsk_typ_id" 
            :fields="{ 
                index: 'typ_id', text:'typ_name', autofocus: true, outlined: true, dense: true,
                search:'typ_name', multiSelect:true, prefetch: false, params:''
        }"></AutocompleteMulti>
    </v-flex>
    <v-flex xs6 class="pa-0 pr-2">
        <v-subheader >Etat</v-subheader>
        <v-alert border="left" class="ma-0 mb-1 pa-0" dense :colored-border="( usrFilter.tsk_sta_id.includes(item.sta_id) ? false : true )"
            :color="item.sta_color" elevation="1" v-for="(item, index) in filterSource.tsk_sta_id" :key="index">
            <v-row align="center" >
                <v-col class="pa-1 pl-6 grow">{{ item.sta_label }}</v-col>
                <v-col class="pa-1 pr-4 shrink"><v-switch v-model="usrFilter.tsk_sta_id" :value="item.sta_id" color="white"></v-switch></v-col> <!-- :color="item.sta_color" -->
            </v-row>        
        </v-alert>
    </v-flex>
    <v-flex xs6 class="pa-0 pr-2">
        <v-subheader >Devis/Régie/Travaux</v-subheader>
        <v-alert border="left" class="ma-0 mb-1 pa-0" dense :colored-border="( usrFilter.eve_sta_id.includes(item.sta_id) ? false : true )"
            :color="item.sta_color" elevation="1" v-for="(item, index) in filterSource.eve_sta_id" :key="index">
            <v-row align="center" >
                <v-col class="pa-1 pl-6 grow">{{ item.sta_label }}</v-col>
                <v-col class="pa-1 pr-4 shrink"><v-switch v-model="usrFilter.eve_sta_id" :value="item.sta_id" color="white"></v-switch></v-col> <!-- :color="item.sta_color" -->
            </v-row>        
        </v-alert>
    </v-flex>
</v-layout>
</v-card-text>
<v-card-actions>
    <v-spacer></v-spacer>
    <v-btn small rounded color="success" @click="closeWindow()">Appliquer</v-btn>
</v-card-actions>
</v-card>
</v-container>
</template>

<script>
import DatePicker from '@/components/ui/DatePicker'
import { cacheGetters, cacheMutations, cacheActions } from "@/store/cache.module"
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
    components: { AutocompleteMulti, DatePicker },
    data: function () {
        return {
            fieldStyle: { outlined: true, dense: true },
            saveProgress: false,
            loading: { getItem: true },
            menu: false,
            dateMenu: { tsk_date_plan: false, tsk_date_end: false },
            datesRange: [
                { min_tsk_date_plan: null, max_tsk_date_plan: null }
            ],
            zon_id    : undefined,
            usrFilter: {
                tsk_sta_id: [],
                tsk_typ_id: [],
                tsk_tty_id: [],
                min_tsk_date_plan_arr:  [], 
                max_tsk_date_plan_arr:  [],
                eve_sta_id: [],
            },
            filterSource:{
                tsk_sta_id: [],
                tsk_typ_id: this.getCacheType('TSK'),
                tsk_tty_id: [],
                typ: [],
                eve_sta_id: [],
            },
            typSource: [],

            filterTab : [],
            sitTab : [],
            storeFilter: {},
            eveState: this.getCacheState('GEN'),
            colorPalette : ['#2196F3', '#009688', '#CDDC39', '#FF9800', '#9C27B0', '#607D8B', '#F44336', '#673AB7', '#03A9F4', '#4CAF50', '#FFEB3B', '#795548' ]
        }
    },
    watch: {
        filterView () {
            cacheMutations.setFilter(this.usrFilter)
            this.updateFilter()
        }
    },
    beforeMount: function(){
        this.initFilter()
    },
    mounted:function (){
        
    },
    methods: {
        initFilter(){
            let cacheFilter     = cacheGetters.getFilter()
            for(var prop in this.usrFilter){
                if( cacheFilter[prop] && prop.substring(prop.length -4 ) !== '_arr' ){
                    this.usrFilter[prop] = cacheFilter[prop]
                }
            }
            // exception
            if( cacheFilter['min_tsk_date_plan_arr'] && cacheFilter['max_tsk_date_plan_arr'] && cacheFilter['min_tsk_date_plan_arr'].length ){
                this.datesRange = []
                cacheFilter['min_tsk_date_plan_arr'].forEach( (val, index) => {
                    this.datesRange.push( { min_tsk_date_plan: val, max_tsk_date_plan: cacheFilter['min_tsk_date_plan_arr'][index] } )
                })
            }

            this.storeFilter    = JSON.parse( JSON.stringify( this.usrFilter ) ) // todoo init if empty
            this.filterSource.tsk_typ_id = cacheGetters.getTypes( 'TSK' )
            this.filterSource.tsk_sta_id = this.getCacheState('TSK').filter(sta => sta.sta_visibility === 1)
            this.filterSource.eve_sta_id = this.getCacheState('TSK')
            this.filterSource.ses        = this.getCacheSession()
        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.usrFilter ){
                if( this.usrFilter[prop] && Array.isArray( this.usrFilter[prop] ) && this.storeFilter[prop] ){
                    diff = this.storeFilter[prop].filter(x => this.usrFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.usrFilter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.usrFilter.tsk_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.usrFilter.tsk_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            cacheMutations.setFilter( this.usrFilter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.usrFilter ) )
        },
        clearFilter(){
            var usrFilter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in usrFilter){
                if( Object.prototype.toString.call( this.usrFilter[prop] ) === '[object Array]' ) {
                    this.usrFilter[prop] = usrFilter[prop].slice( this.usrFilter[prop] )
                } else if( this.usrFilter[prop] !== undefined ){
                    this.usrFilter[prop] = usrFilter[prop]
                }
            }
            this.updateFilter()
        },
        resetFilter(){
            for( let prop in this.usrFilter ){
                if( this.usrFilter[prop] && Array.isArray( this.usrFilter[prop] ) && this.storeFilter[prop] ){
                    this.usrFilter[prop] = []
                } else {
                    this.usrFilter[prop] = null
                }
            }
        },
        closeWindow(){
            // dates rance
            this.usrFilter.min_tsk_date_plan_arr = []
            this.usrFilter.max_tsk_date_plan_arr = []
            this.datesRange.forEach(item => {
                if( item.min_tsk_date_plan && item.max_tsk_date_plan ){
                    this.usrFilter.min_tsk_date_plan_arr.push(item.min_tsk_date_plan)
                    this.usrFilter.max_tsk_date_plan_arr.push(item.max_tsk_date_plan)
                }
            })
            cacheMutations.setFilter(this.usrFilter)
            this.$emit('update:filterView', false)
            filterUpdateService.sendFilter('filter')
        },
        removeChip (fromFilter, id) {
            const index = this.usrFilter[fromFilter].indexOf(id)
            if (index >= 0) this.usrFilter[fromFilter].splice(index, 1)
        },
        addDates(){
            this.datesRange.push( { min_tsk_date_plan: null, max_tsk_date_plan: null } )
        },
        removeDate(index){
            this.datesRange.splice(index, 1)
        },
        getCacheState   : cacheGetters.getStates,
        getCacheType    : cacheGetters.getTypes,
        getCacheSession : cacheGetters.getSession,
    }
}
</script>