<template>
    <v-menu v-if="listZone.length" v-model="zoneMenu" offset-y transition="slide-x-transition" z-index="902" rounded="rounded-lg" content-class="rounded-lg">
    <template v-slot:activator="{ on }">
        <v-btn text v-on="on" style="text-transform: capitalize;">
        <v-avatar><v-icon dark class="ml-0">mdi-layers-search</v-icon></v-avatar>
        {{ currentZone.zon_name }}
        <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
    </template>
    <v-list dense class="rounded-lg">
        <template v-for="(item, pindex) in listZone">
        <v-list-item :key="pindex" @click="switchZone(pindex)">
            <v-list-item-title>{{item.zon_name}}</v-list-item-title>
        </v-list-item>
        </template>
    </v-list>
    </v-menu>
</template>

<script>
import { cacheGetters, cacheData, cacheMutations } from "@/store/cache.module"
import { filterUpdateService } from '@/rxjsServices';
//import { tskObj }       from '@/mixins/tsk_obj.js'

export default {
    name: 'zone_switch',
    props: [ ],
    mixins: [  ],
    data: function () {
        return {
           zoneMenu: false,
           listZone: []
        }
    },
    computed: {
        user(){
            return cacheData.user
        },
        currentZone(){
            if( this.listZone.length ){
                let result = this.listZone.filter(obj => {
                    return obj.zon_id === cacheData.user.zoneId
                })
                return result[0]
            } else {
                return cacheData.user.zoneId
            }

        }
    },
    beforeMount: function (){
        if( this.$root.$permissions.Zone.before ){
            this.listZone = this.getCacheZone()
        } else {
            this.listZone = cacheData.user.userzone
        }
    },
    methods: {
        switchZone(id){
            this.$http.put( '/users/' + this.user.id, { zoneId : this.listZone[id].zon_id } ).then( () => {
                cacheData.user.zoneId = this.listZone[id].zon_id
                cacheMutations.setUser( cacheData.user )
                
                let filter = cacheGetters.getFilter()
                let urlTask = ''
                for( var prop in filter[prop] ){
                    if( filter[prop] !== null && filter[prop] !== undefined && filter[prop].length > 0 ){
                        urlTask += (urlTask.lenght) ? '&' : ''
                        urlTask += prop + '=' + filter[prop]
                    }
                }
                filterUpdateService.sendFilter('zoneswitch')
                this.$root.$toast({ color: 'success', text: 'Zone modifiée !' })
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState   : cacheGetters.getStates,
        getCacheZone: cacheGetters.getZone,
    },
}
</script>